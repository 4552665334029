import { createRouter, createWebHistory } from "vue-router";
const router = createRouter({
	history: createWebHistory(""),
	routes: [
		{
      path: "/",
      name: "mainLayout",
      component: () => import("@/layouts/MainLayout.vue"),
      children: [
        {
          path: '/',
          name: 'home',
          component: ()=> import('@/components/main/Main.vue'),
        },
        {
          path: '/main',
          name: 'main',
          component: ()=> import('@/components/main/Main.vue'),
        },


        {
          path: '/user/form',
          name: 'FormUser',
          component: ()=> import('@/components/user/FormUser.vue'),
        },
        {
          path: '/test/grid',
          name: 'GridTest',
          component: ()=> import('@/components/test/GridTest.vue'),
        }

      ]
    },
		{
      path: "/",
			name: "subLayout",
			component: () => import("@/layouts/SubLayout.vue"),
      children: [

        {
          path: '/login',
          name: 'login',
          component: () => import("@/components/login/Login.vue"),
        },
        {
          path: '/user/join',
          name: 'join',
          component: () => import("@/components/user/Join.vue"),
        },
        {
          path: '/my',
          name: 'my',
          component: () => import("@/components/user/My.vue"),
        },
        {
          path: '/user/find/id',
          name: 'findid',
          component: () => import("@/components/user/FindId.vue"),
        },
        {
          path: '/user/find/pw',
          name: 'findpw',
          component: () => import("@/components/user/FindPw.vue"),
        },

        {
          path: '/user/join/register',
          name: 'register',
          component: () => import("@/components/user/Register.vue"),
        },
        {
          path: '/user/config',
          name: 'config',
          component: () => import("@/components/user/Config.vue"),
        },

        {
          path: '/nft/list',
          name: 'NftSearch',
          component: () => import("@/components/nft/List.vue"),
        },
        {
          path: '/nft/list/:category?/:group?',
          name: 'NftList',
          component: () => import("@/components/nft/List.vue"),
        },
        {
          path: '/nft/detail/:nftid?',
          name: 'NftDetail',
          component: () => import("@/components/nft/Detail.vue"),
        },
        {
          path: '/nft/buy/:nftid?',
          name: 'NftBuy',
          component: () => import("@/components/nft/Buy.vue"),
        },
        {
          path: '/nft/author',
          name: 'author',
          component: () => import("@/components/nft/Author.vue"),
        },
        {
          path: '/nft/owner',
          name: 'owner',
          component: () => import("@/components/nft/Owner.vue"),
        },
        {
          path: '/nft/favorite',
          name: 'favorite',
          component: () => import("@/components/nft/Favorite.vue"),
        },

        {
          path: '/nft/mint/:category?/:group?',
          name: 'mint',
          component: () => import("@/components/nft/Mint.vue"),
        },
	      /*
        {
          path: '/pay/charge',
          name: 'charge',
          component: () => import("@/components/pay/Charge.vue"),
        },
	*/
        {
          path: '/pay/payment/:nftid?',
          name: 'payment',
          component: () => import("@/components/pay/Payment.vue"),
        },

        /*
        {
          path: '/user/login',
          name: 'userlogin',
          component: ()=> import('@/components/user/Login.vue'),
        },
        */

        {
          path: '/login/naver',
          name: 'naver',
          component: () => import("@/components/login/Naver.vue"),
        },
        {
          path: '/login/google',
          name: 'google',
          component: () => import("@/components/login/Google.vue"),
        },

        {
          path: '/login/kakao',
          name: 'kakao',
          component: () => import("@/components/login/Kakao.vue"),
        },
        {
          path: '/user/nemonic',
          name: 'nemonic',
          component: ()=> import('@/components/user/Nemonic.vue'),
        },

        {
          path: '/policy/terms',
          name: 'terms',
          component: ()=> import('@/components/policy/Terms.vue'),
        },
        {
          path: '/policy/copyright',
          name: 'copyright',
          component: ()=> import('@/components/policy/Copyright.vue'),
        },

        {
          path: '/policy/privacy',
          name: 'privacy',
          component: ()=> import('@/components/policy/Privacy.vue'),
        },

      ]
		},
		{
      path: "/",
			name: "emptyLayout",
			component: () => import("@/layouts/EmptyLayout.vue"),
      children: [

        {
          path: '/test',
          name: 'test',
          component: () => import("@/components/test/Test.vue"),
        },

      ]
		}


  ],
});

/*
const router = createRouter({
	history: createWebHistory(""),
	routes: [
		{
      path: "/",
			name: "home",
			component: "@/App.vue",
		},
		{
      path: "/login",
			name: "login",
			component: () => import("@/components/views/UserLogin.vue"),
		}
  ],
});
*/
export default router;
